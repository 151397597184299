<template>
    <div class="bg-primary font-Poppins">
        <!-- Scrollable -->
        <div>
            <p class="marquee">
                <span class="flex text-opacity-40 text-white font-bold text-xl">- CODE À ACCÈS ILLIMITÉ - PERMIS AUTO À PARTIR DE 520€ - PERMIS MOTO À PARTIR DE 495€ - CONDUITE ACCOMPAGNÉE À PARTIR DE 930€ - CODE À ACCÈS ILLIMITÉ - PERMIS AUTO À PARTIR DE 520€ - PERMIS MOTO À PARTIR DE 495€ - CONDUITE ACCOMPAGNÉE À PARTIR DE 930€ -CODE À ACCÈS ILLIMITÉ - PERMIS AUTO À PARTIR DE 520€ - PERMIS MOTO À PARTIR DE 495€ - CONDUITE ACCOMPAGNÉE À PARTIR DE 930€ - CODE À ACCÈS ILLIMITÉ - PERMIS AUTO À PARTIR DE 520€ - PERMIS MOTO À PARTIR DE 495€ - CONDUITE ACCOMPAGNÉE À PARTIR DE 930€ - &nbsp;</span>
            </p>
            <p class="marquee">
                <span class="flex text-opacity-40 text-white font-bold text-xl">- CODE À ACCÈS ILLIMITÉ - PERMIS AUTO À PARTIR DE 520€ - PERMIS MOTO À PARTIR DE 495€ - CONDUITE ACCOMPAGNÉE À PARTIR DE 930€ - CODE À ACCÈS ILLIMITÉ - PERMIS AUTO À PARTIR DE 520€ - PERMIS MOTO À PARTIR DE 495€ - CONDUITE ACCOMPAGNÉE À PARTIR DE 930€ -CODE À ACCÈS ILLIMITÉ - PERMIS AUTO À PARTIR DE 520€ - PERMIS MOTO À PARTIR DE 495€ - CONDUITE ACCOMPAGNÉE À PARTIR DE 930€ - CODE À ACCÈS ILLIMITÉ - PERMIS AUTO À PARTIR DE 520€ - PERMIS MOTO À PARTIR DE 495€ - CONDUITE ACCOMPAGNÉE À PARTIR DE 930€ - &nbsp;</span>
            </p>
        </div>

        <div class="p-16">
            <div class="px-20 flex flex-col items-center lg:flex-row justify-between text-primary text-opacity-50 font-bold">
                <div class="flex flex-col justify-center items-center mt-8 h-48 w-48 bg-white rounded-2xl hoverCursor hover:bg-gray-200">
                    <svg class="w-20 h-20" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7"></path></svg>
                    <div class="mt-3">CODE à 1€</div>
                </div>
                <div class="flex flex-col justify-center items-center mt-8 h-48 w-48 bg-white rounded-2xl hoverCursor hover:bg-gray-200">
                    <svg class="h-20 w-20" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor" viewBox="0 0 24 24"><path stroke-width="1.2" d="M23.5 7c.276 0 .5.224.5.5v.511c0 .793-.926.989-1.616.989l-1.086-2h2.202zm-1.441 3.506c.639 1.186.946 2.252.946 3.666 0 1.37-.397 2.533-1.005 3.981v1.847c0 .552-.448 1-1 1h-1.5c-.552 0-1-.448-1-1v-1h-13v1c0 .552-.448 1-1 1h-1.5c-.552 0-1-.448-1-1v-1.847c-.608-1.448-1.005-2.611-1.005-3.981 0-1.414.307-2.48.946-3.666.829-1.537 1.851-3.453 2.93-5.252.828-1.382 1.262-1.707 2.278-1.889 1.532-.275 2.918-.365 4.851-.365s3.319.09 4.851.365c1.016.182 1.45.507 2.278 1.889 1.079 1.799 2.101 3.715 2.93 5.252zm-16.059 2.994c0-.828-.672-1.5-1.5-1.5s-1.5.672-1.5 1.5.672 1.5 1.5 1.5 1.5-.672 1.5-1.5zm10 1c0-.276-.224-.5-.5-.5h-7c-.276 0-.5.224-.5.5s.224.5.5.5h7c.276 0 .5-.224.5-.5zm2.941-5.527s-.74-1.826-1.631-3.142c-.202-.298-.515-.502-.869-.566-1.511-.272-2.835-.359-4.441-.359s-2.93.087-4.441.359c-.354.063-.667.267-.869.566-.891 1.315-1.631 3.142-1.631 3.142 1.64.313 4.309.497 6.941.497s5.301-.184 6.941-.497zm2.059 4.527c0-.828-.672-1.5-1.5-1.5s-1.5.672-1.5 1.5.672 1.5 1.5 1.5 1.5-.672 1.5-1.5zm-18.298-6.5h-2.202c-.276 0-.5.224-.5.5v.511c0 .793.926.989 1.616.989l1.086-2z"/></svg>
                    <div class="mt-3">Voiture</div>
                </div>
                <div class="flex flex-col justify-center items-center mt-8 h-48 w-48 bg-white rounded-2xl hoverCursor hover:bg-gray-200">
                    <svg class="w-20 h-20" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7"></path></svg>
                    <div class="mt-3">Moto</div>
                </div>
                <div class="flex flex-col justify-center items-center mt-8 h-48 w-48 bg-white rounded-2xl hoverCursor hover:bg-gray-200">
                    <svg class="w-20 h-20" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"></path></svg>
                    <div class="mt-3 text-center">Conduite accompagnée</div>
                </div>
            </div>
        </div>
        <!-- Scrollable -->
        <div class="">
            <p class="marquee bg-primary">
                <span class="flex text-opacity-60 text-white font-bold text-xl">PROFITEZ DE NOTRE EXPERTISE POUR VOUS FORMER ! - PROFITEZ DE NOTRE EXPERTISE POUR VOUS FORMER ! - PROFITEZ DE NOTRE EXPERTISE POUR VOUS FORMER ! - PROFITEZ DE NOTRE EXPERTISE POUR VOUS FORMER ! - PROFITEZ DE NOTRE EXPERTISE POUR VOUS FORMER ! - PROFITEZ DE NOTRE EXPERTISE POUR VOUS FORMER ! - PROFITEZ DE NOTRE EXPERTISE POUR VOUS FORMER ! - &nbsp;</span>
            </p>
            <p class="marquee bg-primary">
                <span class="flex text-opacity-60 text-white font-bold text-xl">PROFITEZ DE NOTRE EXPERTISE POUR VOUS FORMER ! - PROFITEZ DE NOTRE EXPERTISE POUR VOUS FORMER ! - PROFITEZ DE NOTRE EXPERTISE POUR VOUS FORMER ! - PROFITEZ DE NOTRE EXPERTISE POUR VOUS FORMER ! - PROFITEZ DE NOTRE EXPERTISE POUR VOUS FORMER ! - PROFITEZ DE NOTRE EXPERTISE POUR VOUS FORMER ! - PROFITEZ DE NOTRE EXPERTISE POUR VOUS FORMER ! - &nbsp;</span>
            </p>

        </div>

        
    </div>
</template>

<script>
export default {
    name: 'Cta',

}
</script>

<style scoped>

.marquee {
    width: 100%;
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
}

.marquee span {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 50s linear infinite;
}

.marquee2 span {
  animation-delay: 25s;
}

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}
</style>