<template>
    <div class="map">
        <iframe class="w-full" height="540" src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d185665.34245393868!2d3.161266404332576!3d43.35567183418177!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sAuto%20%C3%A9cole%20blanco!5e0!3m2!1sen!2sfr!4v1639663558396!5m2!1sen!2sfr" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
    </div>  

</template>

<script>
export default {
    name: 'Map'
}
</script>