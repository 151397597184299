<template>
    <div class="bg-primary p-8 md:p-20 font-Poppins">
      <div class="mb-8">
        <div class="flex justify-center text-xl lg:text-3xl mb-2 text-white font-BornSpeed">Près de 5 étoiles sur Google !</div>
        <div class="flex justify-center text-secondary">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
        </svg>
        </div>

      </div>
      <carousel class="w-full">
        <slide>
          <SingleAvis name="Jean Jacques" desc="Ma fille vient d'obtenir son permis B,  auto école très sérieuse.
          Merci Guillaume pour votre patience et votre professionnalisme, toujours à l'écoute et bienveillant .Je recommande à 100%
          " job="Père de famille"/>
        </slide>
        <slide>
          <SingleAvis name="Pauline" desc="Super auto école! Mon moniteur Guillaume a été excellent, très exigeant mais le meilleur des formateurs ! Merci encore." job="Etudiante"/>
        </slide>
        <slide>
          <SingleAvis name="Gladys" desc="Permis obtenue en seulement 20 heures de conduite accélérée en 3 semaine merci à laetitia je la recommande a 100% ❤️" job="Lycéene"/>
        </slide>
        <slide>
          <SingleAvis name="Julie" desc="Auto école au top, merci à ma monitrice Mireille, qui a su me donner confiance, de m' avoir conseillé au mieux. Je recommande 👌" job="Lycéene"/>
        </slide>
        <slide>
          <SingleAvis name="Micka" desc="Super auto école! Mon moniteur Guillaume a été très compétent, bonne ambiance et rigueur à la fois !" job="Etudiant"/>
        </slide>
        <slide>
<SingleAvis name="Sofia" desc="J'ai obtenu mon permis B avec 25 heures de conduite en seulement un mois. Je n'avais jamais conduit auparavant et j'ai obtenu la note de 29/31.
          Monitrice au top ! Attentive, patiente et de bons conseils.
          " job="Etudiante"/>
        </slide>
        <slide>
          <SingleAvis name="Florian" desc="Super auto-école je valide totalement. Je remercie mon moniteur Guillaume pour toutes les leçons  et sa bonne humeur , permis validé !" job="Etudiant"/>
        </slide>
        <slide>
          <SingleAvis name="Mika" desc="Super auto-école, je recommande à ceux qui en recherchent une !" job="Client satisfait"/>
        </slide>
        <slide>
          <SingleAvis name="Dorian" desc="Auto école génial, monitrice superbe qui a réussi avec un temps serré à faire de ma conduite personnelle une conduite d’auto école. Merci Laetitia pour ton travail fourni et merci à l'auto-école d’avoir été parfaite ! A très bientôt" job="Etudiant"/>
        </slide>
        <slide>
          <SingleAvis name="Sandrine" desc=" une belle expérience pour nous.. auto-école professionnelle et consciencieuse. ma belle fille avait peur et ils ont su être à l'écoute. de vrais pros.. je recommande" job="Cliente satisfaite"/>
        </slide>
        <slide>
          <SingleAvis name="Théo" desc="Je recommande très  fortement , le moniteur est à votre écoute, il est rigoureux et pleins de bonne humeur , l’accompagnement a était parfait jusqu’au permis .
          Merci à toi Guillaume et à bientôt sur les routes !
          " job="Etudiant"/>
        </slide>
        <slide>
          <SingleAvis name="Bruno" desc="Conducteur depuis plus de 25 ans j'ai dû repasser le permis en France suite à un changement de pays. Mon passage à l'auto-école Blanco m'a permis de revoir ma conduite et reprendre les bonnes habitudes au volant que j'avais perdu il y a longtemps." job="Client satisfait"/>
        </slide>
      </carousel>
          
          
          
    </div>
      
</template>

<script>
import SingleAvis from '@/components/SingleAvis'
import { Carousel, Slide } from 'vue-carousel';
export default {
  components: { 
    SingleAvis,
    Carousel,
    Slide
    },
    data() {
      return {
      }
    }
}
</script>

