<template>
    <div class="about font-Poppins">
        <div class="w-full flex flex-col md:flex-row items-center">
            <div class="w-full md:w-1/2">
                <img src="../assets/About-1.png" alt="">
            </div>
            <div class="w-full md:w-1/2 p-16">
                <div class="mb-3 text-3xl text-primary font-bold font-BornSpeed">+  de 20 voitures très modernes !</div>
                <div class="mt-8">Se former à la conduite chez l’auto école BLANCO c’est se former dans les meilleurs conditions car nos véhicules sont tout récents, nous possédons des boites automatiques comme des boites manuelles, et quoi de mieux qu’une petite touche de couleur et de modernité pour ne pas paraître inaperçu lors de vos heures de conduite !</div>
            </div>
        </div>
        <div class="w-full flex flex-col md:flex-row items-center">
            <div class="w-full md:w-1/2 p-16">
                <div class="mb-3 text-3xl text-primary font-bold font-BornSpeed">4 agences dans l’hérault !</div>
                <div class="mt-8">Posséder 1 agence c’est bien, 4 agences c’est mieux. L’auto-école BLANCO possède à son actif 4 agences dans 3 villes différentes. 2 autos-école à Béziers, 1 à Sérignan et 1 à Magalas. Nos agences font notre fierté et qu’importe l’agence que vous choisirez vous pouvez être sûr que nos méthodes de pédagogie ne changeront pas !</div>
            </div>
             <div class="w-full md:w-1/2">
                <img src="../assets/About-2.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'About',
}
</script>